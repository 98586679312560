import _objectSpread from "D:/WorkPlace/\u5B98\u7F51/html-bcepd-project-website-digital-read/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, ref, reactive, onMounted, toRefs } from "vue";
import "vue3-video-play/dist/style.css";
import vue3VideoPlay from "vue3-video-play";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {
    vue3VideoPlay: vue3VideoPlay
  },
  setup: function setup(props) {
    var router = useRouter();

    var handleIntroduction = function handleIntroduction() {
      router.push("/introduction");
    };

    var top1 = ref(false);
    var top2 = ref(false);
    var top3 = ref(false);
    var top2_block = ref(null);

    var handleScrollx = function handleScrollx() {
      console.log("滚动高度", window.pageYOffset);

      if (window.pageYOffset > 400) {
        top1.value = true;
      }

      if (window.pageYOffset > 2400) {
        top2.value = true;
      }

      if (window.pageYOffset > 3900) {
        top3.value = true;
      } // console.log('距离顶部高度',top2_block.value.getBoundingClientRect().top)
      // if(top2_block.value.getBoundingClientRect().top < 150){
      // console.log(top.value)
      //     nextTick(()=>{
      //         top.value = top2_block.value.getBoundingClientRect().top
      // 	})
      // }

    };

    onMounted(function () {
      window.addEventListener("scroll", handleScrollx, true);
      handlebackTop();
    });

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView(); // document.getElementById('content1').scrollIntoView()
    }; // 视频配置项


    var data = reactive({
      options: {
        width: "260px",
        // 播放器高度
        height: "517px",
        //播放器高度
        color: "#fff",
        //主题色
        title: "",
        //视频名称
        src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
        //视频源
        muted: false,
        //静音
        webFullScreen: false,
        speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"],
        //播放倍速
        autoPlay: false,
        //自动播放
        loop: false,
        //循环播放
        mirror: false,
        //镜像画面
        ligthOff: true,
        //关灯模式
        volume: 0.3,
        //默认音量大小
        control: true,
        //是否显示控制
        controlBtns: ["audioTrack", "quality", "speedRate", "volume", "setting", "pip", "pageFullScreen", "fullScreen"] //显示所有按钮,

      }
    });
    return _objectSpread(_objectSpread({
      top2_block: top2_block,
      top1: top1,
      top2: top2,
      top3: top3,
      handleIntroduction: handleIntroduction,
      handleScrollx: handleScrollx
    }, toRefs(data)), {}, {
      handlebackTop: handlebackTop
    });
  }
});